import React from "react"
import styled from "styled-components"
// Components
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion"
import Seo from '../components2/Seo';
import Layout from "../components2/Layout";



const FixLayout = styled(Layout)`
@media screen and (min-width: 671px){
    overflow:hidden;
    height: 100vh;
}
padding-top:0px;
position: relative;
background: #fff1de;
`

const SectionWrapper = styled.section`
padding-top: 0;

@media screen and (max-width: 670px){
    padding-top: 100px;
    padding-bottom: 50px;
}

@media screen and (min-width: 671px){
    padding-top: 0px;
    height: calc(100vh - 90px);
}
`

const InnerSectionWrapper = styled.div`
@media screen and (max-width: 1920px){
    max-width: 1330px;
    overflow: hidden;
}

width: 94%;
margin-right: auto;
margin-left: auto;
`

const SectionTitle = styled.h3`

@media screen and (max-width: 1920px) and (min-width: 671px){
    margin-bottom: 0px;
    padding-bottom: 0px;

}

@media screen and (min-width: 671px){
    
    font-size: 3.25rem;
    margin-top: 0;
}

@media screen and (max-width: 670px){
    
    font-size: 1.75rem;
    padding-bottom: 10px;
}

display: -webkit-flex;
display: flex;
-webkit-align-items: center;
align-items: center;
color: #C24C92;
padding-top: 0;

&:before{
    content: "";
    min-width: 55%;
    height: 2px;
    -ms-flex-positive: 1;
    -webkit-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #C24C92;
}


@media screen and (max-width: 670px){
    &:before{
        max-width: 48.125%;
    }
}

&:after{
    content: "";
    min-width: 10%;
    height: 2px;
    -ms-flex-positive: 1;
    -webkit-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #C24C92;
}


@media screen and (max-width: 670px){
    &:after{
        max-width: 48.125%;
    }
}

`

const SectionTitleSpan = styled.h1`
margin-left: auto;
margin-right: auto;
font-family: Anton, Impact, Impact;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
color: #C24C92;
text-align:center;
`

const ArticleGrid = styled.div`
@media screen and (min-width: 769px){
    display: grid;
    grid-template-areas:
        "small small big big"
        "small small big big";
    margin: 0;
    grid-template-columns:minmax(0, 1fr);
}
`

const LargePostPreview = styled.article`
@media screen and (min-width: 769px){
    grid-area: big;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 769px){
    &:not(:first-child) {
        margin-top: 1.5%;
    }
}
`

const LargePostPreviewLinkWrapper = styled(Link)`
position: relative;
display: block;
overflow: hidden;
text-decoration: none;
color: #303C3C;
`

const LargePostPreviewPicture = styled.div`
max-width: 100%;
height: auto;
margin:auto;
min-width: 100%;
@media screen and (min-width: 769px){
    max-height: 400px;
    max-width: 550px;
}
`

const LargePostPreviewPictureImg = styled(GatsbyImage)`
width: 100%;
display: block;
display: block;
max-width: 100%;
height: auto;
`

const LargePostPreviewTextWrapper = styled.div`
@media screen and (max-width: 670px){
    margin: -10px 10px 0px 10px;
    padding: 20px;
}

padding: 20px 40px 30px;
@media screen and (min-width: 1025px){
    padding: 20px 50px 40px;
}

@media screen and (min-width: 671px){
    margin: -20px 20px 0 20px;
}


position: relative;
background: #fff;
z-index: 1;
text-align: center;
`

const LargePostPreviewTextTitle = styled.span`
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;

@media screen and (max-width: 670px){

    
    font-size: .75rem;
}

@media screen and (max-width: 1024px){
    
    font-size: .75rem;
}

@media screen and (min-width: 1025px){
    
    font-size: .875rem;
}
`

const LargePostPreviewText = styled.p`
margin-top: 25px;

@media screen and (max-width: 670px){
    margin-bottom: 10px;
}
@media screen and (max-width: 670px){
    
    font-size: 1.125rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 1.375rem;
}
@media screen and (max-width: 670px){
    
    font-size: 1rem;
    line-height: 180%;
}

@media screen and (min-width: 1025px){
    
    font-size: 1.625rem;
}
`

const LargePostPreviewLink = styled(Link)`
text-decoration: none;
color: #303C3C;
`

/* SMALL POSTS */

const SmallPostPreview2Column = styled.div`
@media screen and (max-width: 768px){
    margin-top: 3.75%;
}

@media screen and (min-width: 769px){
    grid-area: small;
    grid-area: small;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 50vw;
}



`


const SmallPostPreview = styled.article`
@media screen and (min-width: 769px){
    width: 45%;
    display:inline-block;
}
`


const SmallPostPreviewLinkWrapper = styled(Link)`
position: relative;
display: block;
overflow: hidden;
text-decoration: none;
color: #303C3C;
`

const SmallPostPreviewPicture = styled.div`
max-width: 100%;
max-height: 30vh;
display:flex;
`

const SmallPostPreviewPictureImg = styled(GatsbyImage)`
width: 100%;
display: block;
max-width: 100%;
height: auto;
align-self:center;
`

const SmallPostPreviewTextWrapper = styled.div`
@media screen and (max-width: 670px){
    padding-top: 10px;
}

@media screen and (min-width: 671px){
    padding: 10px 10px 0;
}

text-align: center;

`

const SmallPostPreviewTextTitle = styled.span`
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
letter-spacing: .1em;

@media screen and (max-width: 670px){
    
    font-size: .75rem;
}

@media screen and (max-width: 1024px){
    
    font-size: .75rem;
}
`

const SmallPostPreviewText = styled.p`
line-height: 180%;

@media screen and (max-width: 670px){
    margin-bottom: 20px;
}


@media screen and (max-width: 670px){
    
    font-size: 1rem;
}
@media screen and (max-width: 1024px){
    
    font-size: 1rem;
}
`

const SmallPostPreviewLink = styled(Link)`
text-decoration: none;
color: #303C3C;
`

const NextLink = styled(Link)`
@media screen and (max-width:670px){
    text-align: center;
}
-webkit-flex-shrink: 0;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
-webkit-letter-spacing: .1em;
-moz-letter-spacing: .1em;
-ms-letter-spacing: .1em;
letter-spacing: .1em;
grid-area: Right;
text-align: right;
${({ isLast }) => isLast && `
    visibility: hidden;
    `}
`

const PrevLink = styled(Link)`
-webkit-flex-shrink: 0;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
-webkit-letter-spacing: .1em;
-moz-letter-spacing: .1em;
-ms-letter-spacing: .1em;
letter-spacing: .1em;
grid-area: Left; 
text-align: left;
${({ isFirst }) => isFirst && `
    visibility: hidden;
    `}
`


const BlogPostNavigationBar = styled.nav`
display: grid; 
gap: 0px 0px; 
grid-auto-columns: minmax(0, 1fr);
grid-template-areas: 
    "Left Middle Right"; 
margin-top: 25px;
`


const AllLink = styled(Link)`
-webkit-flex-shrink: 0;
-webkit-flex-shrink: 0;
-ms-flex-negative: 0;
flex-shrink: 0;
display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #C24C92;
text-transform: uppercase;
-webkit-letter-spacing: .1em;
-moz-letter-spacing: .1em;
-ms-letter-spacing: .1em;
letter-spacing: .1em;
text-align: center;
grid-area: Middle; 
`

const Tags = ({ pageContext, data }) => {
  
  const posts = data.allMdx.edges
  const { tagName, currentPage, numTagPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numTagPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

    const tagNameUrlFriendly = tagName.replace(/\s+/g, '-');

  return (
    <FixLayout>
        <Seo title={`${tagName} Category Page #${currentPage}`} description={`This is the #${currentPage} category page for all ${tagName} posts`} defer={false}>
        </Seo>
     <SectionWrapper>
    <InnerSectionWrapper>

        <SectionTitle>
            <SectionTitleSpan>{tagName}</SectionTitleSpan>
        </SectionTitle>

        
       <ArticleGrid>

            
        <SmallPostPreview2Column>
            {posts &&
            posts.slice(1, 5).map(({ node: post }) => (
            <SmallPostPreview
            key={post.id}>
                <SmallPostPreviewLinkWrapper to={`/${post.slug}`}>
                    <SmallPostPreviewPicture
                     as={motion.div}
                     whileHover ={{
                         scale : [1, 1.1],
                         zIndex:  99,
                         transition: {
                             duration: .4
                         }
                         }}>
                        <SmallPostPreviewPictureImg
                            image={post.frontmatter.featuredimage.childImageSharp.small}
                            alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                            />
                    </SmallPostPreviewPicture>
                </SmallPostPreviewLinkWrapper>
                <SmallPostPreviewTextWrapper>
                    <SmallPostPreviewTextTitle>{post.frontmatter.tags[0]}</SmallPostPreviewTextTitle>
                    <SmallPostPreviewText>
                        <SmallPostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</SmallPostPreviewLink>
                    </SmallPostPreviewText>
                </SmallPostPreviewTextWrapper>
            </SmallPostPreview>
            ))}
        </SmallPostPreview2Column>
        {posts &&
        posts.slice(0, 1).map(({ node: post }) => (
            <LargePostPreview key={post.id}>
                <LargePostPreviewLinkWrapper to={`/${post.slug}`} >
                    <LargePostPreviewPicture
                    as={motion.div}
                    whileHover ={{
                        scale : [1, 1.1],
                        zIndex:  99,
                        transition: {
                            duration: .4
                        }
                        }}
                        >
                        <LargePostPreviewPictureImg
                            image={post.frontmatter.featuredimage.childImageSharp.large}
                            alt= {`featured image thumbnail for post ${post.frontmatter.title}`}
                            />
                    </LargePostPreviewPicture>
                </LargePostPreviewLinkWrapper>
            
                <LargePostPreviewTextWrapper>
                    <LargePostPreviewTextTitle>{post.frontmatter.tags[0]}</LargePostPreviewTextTitle>
                    <LargePostPreviewText>
                        <LargePostPreviewLink to={`/${post.slug}`}>{post.frontmatter.title}</LargePostPreviewLink>
                    </LargePostPreviewText>
                </LargePostPreviewTextWrapper>
                <BlogPostNavigationBar
          role="navigation"
          aria-label="pagination"
        >
         {currentPage !== 1 && 
          <PrevLink
            to={`/${tagNameUrlFriendly}/${prevPage}`}
            className="pagination-previous"
            rel="prev"
            isFirst = {isFirst}
          >
            ⬅ Previous Page
          </PrevLink>
        }


    <AllLink to="/blog">All tags</AllLink>
    {currentPage !== numTagPages && 
          <NextLink
            to={`/${tagNameUrlFriendly}/${nextPage}`}
            className="pagination-next"
            rel="next"
            isLast = {isLast}
          >
            Next Page ⮕
          </NextLink>
    }
        </BlogPostNavigationBar>
            </LargePostPreview>
        ))}  
        </ArticleGrid>
       
      </InnerSectionWrapper>
    </SectionWrapper>
    </FixLayout>
  )
}


export default Tags

export const pageQuery = graphql`
  query($tagName: String, $skip: Int!, $limit: Int!) {
    allMdx(
    sort: { fields: [frontmatter___date], order: DESC }
    filter: {frontmatter: {tags: {in: [$tagName]}, templateKey: {eq: "blog-post"}}}
    skip: $skip 
    limit: $limit
    ) 
    {   totalCount
        edges {
          node {
            id
            slug
            frontmatter {
              title
              tags
              featuredimage {
                childImageSharp {
                    large: 
                        gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                        sizes: "(max-width: 1000px) 400px, 50vw"
                      )
                    small: 
                        gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                        sizes: "(max-width: 1000px) 400px, 25vw"
                      )
                  }
              }
            }
          }
        }
      }
  }
`